//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import User from '~/components/User.vue';
import constant from '~/const';
import Filters from './TableFilters.vue';
import Modal from './Modal.vue';
import Extra from './TableExtra.vue';

import tableConfig from './tableConfig';
import statusTableConfig from './statusTableConfig';

export default {
  components: {
    Filters,
    Modal,
    Extra,
    User,
  },

  data() {
    return {
      tableConfig,
      statusTableConfig,
      selection: [],
      loader: false,
      constant,
      statuses: [],
      actionsConfig: constant.traders.STATUS_ACTIONS_CONFIG,
      transactionModal: {
        show: false,
        transactionId: null,
        action: '',
      },
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapGetters('common', ['itemsPerPage']),

    ...mapState('tradersExchange', ['page', 'pages', 'data', 'sort']),
  },

  // SINGLE TABLE COMPONENT
  async mounted() {
    const { page } = this.$route.query;
    const numberPage = Number(page);
    if (numberPage) {
      this.setPageAction(numberPage);
    }

    this.setGeneralLoader(true);
    await Promise.allSettled([
      this.loadDataAction(),
      this.loadRateCurrencies(),
      this.loadCurrencies(),
    ]);
    this.setGeneralLoader(false);
  },

  methods: {
    ...mapActions('common', [
      'setGeneralLoader',
      'setGeneralProgress',
      'confirmAction',
      'setSuccessNotification',
      'setErrorNotification',
    ]),
    ...mapActions('tradersRates', {
      loadRateCurrencies: 'loadRateCurrencies',
    }),
    ...mapActions('tradersExchange', {
      loadDataAction: 'loadData',
      setPageAction: 'setPage',
      setSortAction: 'setSort',
      confirmTransaction: 'confirmTransaction',
      completeTransaction: 'completeTransaction',
      cancelTransaction: 'cancelTransaction',
    }),
    ...mapActions('tradersCurrencies', {
      loadCurrencies: 'loadData',
    }),

    getStatus(row) {
      if (row.status === 'Error' && !row.valid) {
        return this.$t(`const.${constant.merchants.INVOICE_STATUS_LIST[constant.merchants.INVOICE_STATUS_CODES.NotCreated].caption}`);
      }
      return this.$t(`const.${constant.merchants.INVOICE_STATUS_LIST[constant.merchants.INVOICE_STATUS_CODES[row.status]].caption}`);
    },

    transactionAction(payload, actionType) {
      this.transactionModal.show = true;
      this.transactionModal.transactionId = payload.id;
      this.transactionModal.action = actionType;
    },

    getStatusColor(status) {
      switch (status) {
        case 'Error':
        case 'DepositError':
        case 'WithdrawalError':
        case 'Issue':
        case 'Expired':
        case 'Cancelled':
          return 'error--text';
        case 'DepositAwaiting':
        case 'WithdrawalAwaiting':
          return 'warning--text';
        case 'DepositProcessing':
        case 'WithdrawalProcessing':
          return 'primary--text';
        case 'Completed':
        case 'Confirmed':
          return 'success--text';
        default:
          return '';
      }
    },

    applyAction(payload) {
      const { action, id, comment } = payload;

      this.confirmAction({
        title: `Are you sure you want to ${action} the transaction ${id}?`,
        callback: async () => {
          this.setGeneralProgress(true);
          this.loader = true;
          try {
            await this[`${action}Transaction`]({ id, comment });
            this.transactionModal.show = false;
            await this.loadData();
            this.setSuccessNotification(`${action} action successful`);
          } catch (error) {
            this.setErrorNotification(error.message);
          }
          this.setGeneralProgress(false);
          this.loader = false;
        },
      });
    },

    async loadData(data = {}) {
      this.setGeneralProgress(true);
      this.loader = true;
      await this.loadDataAction(data);
      this.setGeneralProgress(false);
      this.loader = false;
    },

    applyFilters() {
      const { page } = this;
      if (page > 1) {
        this.setPageAction(1);
        this.$router.replace({ query: {} });
      }

      this.loadData();
    },

    applySort(data) {
      this.setSortAction(data);
      this.loadData();
    },

    setPage(value) {
      this.setPageAction(value);

      // SINGLE TABLE COMPONENT
      // const query = { page: value };
      // this.$router.replace({ query });

      const extendedRequestData = {
        getTotal: false,
      };

      this.loadData(extendedRequestData);
    },
  },
};
