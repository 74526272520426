//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex';
import constant from '~/const';
import Filters from '~/components/filters/Index.vue';
// import DateRange from '~/components/ui/DateRange.vue';

export default {
  components: { Filters },
  props: {
    isLoading: { type: Boolean, default: false },
  },

  data() {
    return {
      constant,
      filters: {
        status: [],
        createdFrom: '',
        createdTo: '',
        amountFrom: undefined,
        amountTo: undefined,
        currency: [],
      },
    };
  },

  computed: {
    ...mapState('tradersCurrencies', {
      currencies: 'data',
    }),
    ...mapState('tradersExchange', ['filterLists']),

    getStatuses() {
      const resultArray = Object.keys(this.constant.merchants.INVOICE_STATUS_CODES).map((key) => {
        const foundItem = this.constant.merchants.INVOICE_STATUS_LIST.find((item) => item.id === this.constant.merchants.INVOICE_STATUS_CODES[key]);
        return {
          id: key,
          caption: foundItem ? foundItem.caption : 'Unknown',
        };
      });
      return resultArray;
    },
  },

  watch: {
    filters: {
      async handler(data) {
        await this.setFilters(data);
        this.$emit('update');
      },
      deep: true,
    },
  },

  created() {
    this.setFilters({});
  },

  methods: {
    ...mapActions('tradersExchange', ['setFilters']),
  },
};
