export default [
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Id',
    },
    cell: {
      type: 'text',
      value: (e) => e.id,
      tooltip: true,
    },
  },
  {
    width: 9,
    header: {
      type: 'text',
      caption: 'External Order ID',
    },
    cell: {
      type: 'text',
      value: (e) => e.externalOrderID,
    },
  },
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'User',
    },
    cell: {
      type: 'slot',
      name: 'user',
    },
  },
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'UID',
    },
    cell: {
      type: 'text',
      value: (e) => e.uid,
      tooltip: true,
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Date',
    },
    cell: {
      type: 'slot',
      name: 'created',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Currency',
    },
    cell: {
      type: 'text',
      value: (e) => e.currency,
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Amount',
    },
    cell: {
      type: 'text',
      value: (e) => e.amount,
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Quantity',
    },
    cell: {
      type: 'text',
      value: (e) => e.productQuantity,
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Type',
    },
    cell: {
      type: 'slot',
      name: 'type',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Status',
    },
    cell: {
      type: 'slot',
      name: 'status',
    },
  },
];
