var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section"},[_c('div',{staticClass:"section__filters"},[_c('Filters',{attrs:{"is-loading":_vm.loader},on:{"update":_vm.applyFilters}})],1),_c('div',{staticClass:"section__table"},[_c('UiTable',{attrs:{"selection":_vm.selection,"data":_vm.data,"config":_vm.tableConfig,"is-loading":_vm.generalLoader || _vm.loader,"per-page":_vm.itemsPerPage,"sort":_vm.sort,"show-extra":"","fixed-header":""},on:{"update:selection":function($event){_vm.selection=$event},"sort":_vm.applySort},scopedSlots:_vm._u([{key:"user",fn:function(ref){
var row = ref.row;
return [_c('User',{attrs:{"id":Number(row.userId),"username":row.userName}})]}},{key:"created",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$utils.getDate(new Date(row.date * 1000)))+" "),_c('span',{staticClass:"text--secondary"},[_vm._v(" "+_vm._s(_vm.$utils.getTime(new Date(row.date * 1000)))+" ")])]}},{key:"type",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$t(row.type))+" ")]}},{key:"status",fn:function(ref){
var row = ref.row;
return [_c('span',{class:_vm.getStatusColor(row.status)},[_vm._v(" "+_vm._s(_vm.getStatus(row))+" ")])]}},{key:"extra",fn:function(ref){
var row = ref.row;
return [_c('Extra',{attrs:{"order":row}})]}}])})],1),_c('div',{staticClass:"d-flex justify-end"},[_c('ContentPagination',{attrs:{"page":_vm.page,"pages":_vm.pages,"per-page":""},on:{"input":_vm.setPage,"inputPerPage":_vm.applyFilters}})],1),(_vm.transactionModal.show)?[_c('Modal',{attrs:{"transaction-id":_vm.transactionModal.transactionId,"action":_vm.transactionModal.action},on:{"apply":_vm.applyAction},model:{value:(_vm.transactionModal.show),callback:function ($$v) {_vm.$set(_vm.transactionModal, "show", $$v)},expression:"transactionModal.show"}})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }