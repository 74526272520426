//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex';
import constant from '~/const';

export default {

  props: {
    order: { type: Object, default: () => ({}) },
  },

  computed: {
    ...mapState('common', ['generalLoader']),
  },

  data() {
    return {
      constant,
      ready: false,
      data: {},
    };
  },

  created() {
    this.getOrderData();
  },

  methods: {
    ...mapActions('common', ['setGeneralProgress']),
    ...mapActions('tradersExchange', {
      getOrderDataAction: 'getOrderData',
    }),

    async getOrderData() {
      this.ready = false;
      this.setGeneralProgress(true);
      this.data = await this.getOrderDataAction(this.$props.order.id);
      this.ready = true;
      this.setGeneralProgress(false);
    },
  },
};
